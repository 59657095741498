import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(() => ({
  text: {
    marginTop: '1.5rem'
  },
  heading: {
    marginTop: '2rem',
    fontSize: '2em',
    fontWeight: 700
  }
}));

const System = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  
  return (
    <div className={className} {...rest}>
      <SectionHeader
        title="The Accomplish System Overview"
        align="left"
        titleProps={{
          color: 'primary'
        }}
      />
      <Typography component="p" className={classes.text}>
        The Accomplishment System at it's heart is a set of tools and principles that can be used to accomplish just
        about anything.
      </Typography>
      <Typography component="h5" className={classes.heading}>
        Tools
      </Typography>
      <Typography component="p" className={classes.text}>
        Accomplishment Trackers
      </Typography>
      <Typography component="p" className={classes.text}>
        Review Cycles
      </Typography>
      <Typography component="p" className={classes.text}>
        Daily Commitment List
      </Typography>
      <Typography component="p" className={classes.text}>
        Progress Journal
      </Typography>
      <Typography component="h5" className={classes.heading}>
        Principles
      </Typography>
      <Typography component="p" className={classes.text}>
        If you want things to change, you must do something different
      </Typography>
      <Typography component="p" className={classes.text}>
        Focusing on outcomes, rather than just the work, will bring better results
      </Typography>
      <Typography component="p" className={classes.text}>
        Commitments are more powerful than assignments
      </Typography>
      <Typography component="p" className={classes.text}>
        Ownership cultivates creativity
      </Typography>
    </div>
  );
};

System.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default System;
