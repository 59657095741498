import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import { Avatar, Box, List, ListItem, ListItemText, Typography, ListItemAvatar, Grid } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { CardBase } from 'components/organisms';
import Trademarked from '../../../../components/atoms/Trademarked';

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main
  },
  cardText: {
    color: theme.palette.primary.contrastText,
    fontWeight: 900
  },
  blue: {
    backgroundColor: theme.palette.accent.blue
  },
  blueText: {
    color: theme.palette.accent.blue
  },
  green: {
    backgroundColor: theme.palette.accent.green
  },
  greenText: {
    color: theme.palette.accent.green
  },
  red: {
    backgroundColor: theme.palette.accent.red
  },
  redText: {
    color: theme.palette.accent.red
  },
  yellow: {
    backgroundColor: theme.palette.accent.yellow
  },
  yellowText: {
    color: theme.palette.accent.yellow
  },
  text: {
    marginTop: '1.5rem'
  }
}));

const Story = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  
  return (
    <div className={className} {...rest}>
      <SectionHeader
        title="History - The Why"
        align="left"
        disableGutter
        titleProps={{
          component: 'h2',
          color: 'primary'
        }}
      />
      <Typography component="h3" variant="h6" className={classes.text}>
        Story from Geoff:
      </Typography>
      <Typography component="p" className={classes.text}>
        The year was about 2005, and I was working as an office helper doing random IT support, and number crunching using spreadsheets. I was young and said yes to just about everything, if someone needed help, I would give it my best shot. Pretty soon my task lists were out of control, and over time I would just feel buried in the day to day grind of trying to get things done, while trying to remember the important stuff that wasn’t getting done, because of all the other things I was doing.
      </Typography>
      <Typography component="p" className={classes.text}>
        I started to read books about productivity and getting things done. I would employ the tricks and tips and it would help for somethings and then I would be back in the thick of it. I remember thinking, there has got to be some solution for keeping track of things that I am supposed to do, and will help me organize and figure out the most important things to do each day.       </Typography>
      <Typography component="p" className={classes.text}>
        Over the years, I tried all kinds of apps, and planners, and calendars, and systems, and strategies… and they all were missing parts and pieces. The tools available ranged from much too simple, just lists of things that I already I needed to get done, to much too complicated project management tools, requiring a full time manager or assistant to learn and used daily. I had even attempted to create a few apps myself over the years, and they all fell short. Here are a few of the concepts I prototyped:
      </Typography>
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <CheckIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            ActivTask - An app that would allow you to make multiple lists, and just pull the first thing off each. So I could focus on just the next most important thing.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <CheckIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            Commmit - A task management app that was commitment based rather than assignment based. I know there was power in really understanding how much time there was in a day, and only committing to things you were sure you could accomplish.          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <CheckIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            Tag your it - A team based task management where you easily passed the task to the next person on the team that needed to do something about it.
          </ListItemText>
        </ListItem>
      </List>
      <Typography component="p" className={classes.text}>
        Each of these app attempts uncovered the truth that, even if I used something like this, the discipline to use the app was the most valuable tool, and that wasn’t driven by an app.
      </Typography>
      <Typography component="p" className={classes.text}>
        In 2013, I turned to meditation, because I found trouble focusing. I figured if other people didn’t need an app to help them accomplish things then maybe I just needed to focus and train my brain. This helped dramatically with focus and clarity. Helps to center you to what is really important. If doing less, then it’s easier to focus. But then when you get busy, the stress of keeping track of everything and anxiety of potentially forgetting something is real. Mediation can help with focus, but getting things done is good therapy as well.
      </Typography>
      <Typography component="p" className={classes.text}>
        Fast forward to early 2018, I was again finding myself overwhelmed and wondering why someone else hadn’t created tools that would help a busy guy like me to organize what I was trying to get done. I was on a plane flying home from a multi-day client meeting, and I was beat. My head hurt from the amount of information I just went over with the client, and it came to me… the concept of a system for getting things done in an efficient, reliable, and consistent way. A structured notebook. Picture if you will a shelf of notebooks, each one for different things you want to accomplish. Whether it be being more efficient with projects at work, learning a new language, starting a business, being a better father, the pattern is the same. So, in these notebooks we can have a set of tabs:
      </Typography>
      <Box marginBottom={2} marginTop={2}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <CardBase withShadow className={classes.blue}>
              <Typography variant="h6" component="p" className={classes.cardText}>1) Dream</Typography>
            </CardBase>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardBase withShadow className={classes.yellow}>
              <Typography variant="h6" component="p" className={classes.cardText}>2) Learn</Typography>
            </CardBase>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardBase withShadow className={classes.red}>
              <Typography variant="h6" component="p" className={classes.cardText}>3) Plan</Typography>
            </CardBase>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardBase withShadow className={classes.green}>
              <Typography variant="h6" component="p" className={classes.cardText}>4) Do</Typography>
            </CardBase>
          </Grid>
        </Grid>
      </Box>
      <Typography component="p" className={classes.text}>
        There is a pattern of accomplishment. A sequence that you need to follow at some level to truly move the needle on what you are trying to do or become.
      </Typography>
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.blue}>
              <Typography component="span">1</Typography>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            First, you have to visualize a new version of something, be it yourself or world around you. You have to {' '}
            <Typography component="span" className={classes.blueText}>dream</Typography>.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.yellow}>
              <Typography component="span">2</Typography>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            Second, you have to{' '}
            <Typography component="span" className={classes.yellowText}>learn</Typography>{' '}
            the process or steps, the effort involved, to get yourself from where you are now, to that future version of yourself.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.red}>
              <Typography component="span">3</Typography>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            Third, you have to make{' '}
            <Typography component="span" className={classes.redText}>a plan</Typography>{' '}
            for getting yourself there. Meaning, from what you learn, you need to see stepping stones, milestones, and desired outcomes along the way that will lead you to the brighter future.           </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.green}>
              <Typography component="span">4</Typography>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            Fourth, you need to{' '}
            <Typography component="span" className={classes.greenText}>do</Typography>{' '}
            things, commit and take action along that accomplishment path that you have found as you have learned and planned.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <Typography component="span">5</Typography>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            And Lastly, you have to set a review cycle, so that you revisit the dream, and plan regularly to figure out the next step on the journey.
          </ListItemText>
        </ListItem>
      </List>
      <Typography component="p" className={classes.text}>
        Based on all the books I have read… all the planner systems I had attempted… failed projects… TED Talks I listened to…
      </Typography>
      <Typography component="p" className={classes.text}>
        These concepts resonated with me.
      </Typography>
      <Typography component="p" className={classes.text}>
        I immediately started sketching out the next app idea, but then remembered my failed app attempts in the past. I thought, I wonder if this would work on paper. Rather than building an app, could I just take the steps to create the tools I needed with literal notebooks, pen and paper. From January to October that year, I started using the beginnings of what I would call The Accomplishment System. A system of a couple tools that included: Tracker worksheet documents and notebooks, review cycle grids, and daily commitment journals. Using these, I was able to keep track of what I was working on.
      </Typography>
      <Typography component="p" className={classes.text}>
        By using these tools, I was able to be very efficient over the next 10 months. I had a very time consuming day job, a thriving side business, and an active family and church life.
      </Typography>
      <Box marginBottom={2} marginTop={2}>
        <CardBase withShadow>
          <Typography component="p">
            March 2018, started compiling training materials that small business owners could use to use the paper version of the system.
          </Typography>
        </CardBase>
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <CardBase withShadow>
          <Typography component="p">
            October 2018, decided that the paper system worked, and needed a tool to help just keep track of the Tracker documents and the review cycle. So, I started planning out a simple application that I could use to transition the review cycles to an automated system for efficiency.
          </Typography>
        </CardBase>
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <CardBase withShadow>
          <Typography component="p">
            January 2019, officially started sharing a Mac Desktop app that had some basic features. Used Local data storage, no syncing, just a basic app for personal use. Called it <Trademarked>Formigio</Trademarked> Personal.
          </Typography>
        </CardBase>
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <CardBase withShadow>
          <Typography component="p">
            November 2019, released version of Personal with Login capabilities and data sync with basic cloud storage.
          </Typography>
        </CardBase>
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <CardBase withShadow>
          <Typography component="p">
            March 2020, subscriptions added for more storage space and data.
          </Typography>
        </CardBase>
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <CardBase withShadow>
          <Typography component="p">
            May 2020, released iOS app, called <Trademarked>Formigio</Trademarked> Companion to first group of beta testers.
          </Typography>
        </CardBase>
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <CardBase withShadow>
          <Typography component="p">
            Feb 2021, iOS app feature complete with <Trademarked>Formigio</Trademarked> Personal app.
          </Typography>
        </CardBase>
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <CardBase withShadow>
          <Typography component="p">
            June 2021, The official web application launched.
          </Typography>
        </CardBase>
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <CardBase withShadow>
          <Typography component="p">
            Sept 2021, Additional Subscriptions Available via Stripe payments
          </Typography>
        </CardBase>
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <CardBase withShadow>
          <Typography component="p">
            Dec 2021, More planning capabilities added with Plans and Milestone Action Items
          </Typography>
        </CardBase>
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <CardBase withShadow>
          <Typography component="p">
            Sept 2022, Sharing Trackers and Working Together Started
          </Typography>
        </CardBase>
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <CardBase withShadow>
          <Typography component="p">
            Sept 2022, Sharing Trackers and Working Together Started. SMS Text Notification Reminders.
          </Typography>
        </CardBase>
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <CardBase withShadow>
          <Typography component="p">
            Oct 2022, User Role Tags for Tracker Documents, Commitment Trays for organizing focus lists for the day.
            Completed Dialog Added for assisting with journaling progress and capturing next steps.
          </Typography>
        </CardBase>
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <CardBase withShadow>
          <Typography component="p">
            Nov 2022, Invite to Commit for requesting that others participate in some part of the plan. Tracker templates
            for tasks and projects that are recurring or sharable.
          </Typography>
        </CardBase>
      </Box>
      <Typography component="p" className={classes.text}>
        Now anyone can take advantage of the years of work and testing that has gone into the system and the applications.
      </Typography>
    </div>
  );
};

Story.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Story;
